@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: 'Rubik-Regular';
  src: local('Rubik-Regular'),
    url(../fonts/Rubik-Regular.ttf) format('truetype'),
    url(../fonts/Rubik-Regular.eot?#iefix) format('embedded-opentype'),
    url(../fonts/Rubik-Regular.woff2) format('woff2'),
    url(../fonts/Rubik-Regular.woff) format('woff'),
    url(../fonts/Rubik-Regular.svg#Rubik) format('svg');
}

@font-face {
  font-style: normal;
  font-weight: 300;
  font-family: 'Rubik-Light';
  src: local('Rubik-Light'), url(../fonts/Rubik-Light.ttf) format('truetype'),
    url(../fonts/Rubik-Light.eot?#iefix) format('embedded-opentype'),
    url(../fonts/Rubik-Light.woff2) format('woff2'),
    url(../fonts/Rubik-Light.woff) format('woff'),
    url(../fonts/Rubik-Light.svg#Rubik) format('svg');
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: 'Rubik-Medium';
  src: local('Rubik-Medium'), url(../fonts/Rubik-Medium.ttf) format('truetype'),
    url(../fonts/Rubik-Medium.eot?#iefix) format('embedded-opentype'),
    url(../fonts/Rubik-Medium.woff2) format('woff2'),
    url(../fonts/Rubik-Medium.woff) format('woff'),
    url(../fonts/Rubik-Medium.svg#Rubik) format('svg');
}

@font-face {
  font-style: normal;
  font-weight: 900;
  font-family: 'Rubik-Black';
  src: local('Rubik-Black'), url(../fonts/Rubik-Black.ttf) format('truetype'),
    url(../fonts/Rubik-Black.eot?#iefix) format('embedded-opentype'),
    url(../fonts/Rubik-Black.woff2) format('woff2'),
    url(../fonts/Rubik-Black.woff) format('woff'),
    url(../fonts/Rubik-Black.svg#Rubik) format('svg');
}

@font-face {
  font-style: italic;
  font-weight: 400;
  font-family: 'Rubik-Regular';
  src: local('Rubik-Italic'), url(../fonts/Rubik-Italic.ttf) format('truetype');
}

@font-face {
  font-style: italic;
  font-weight: 300;
  font-family: 'Rubik-Light';
  src: local('Rubik-LightItalic'),
    url(../fonts/Rubik-LightItalic.ttf) format('truetype');
}

@font-face {
  font-style: italic;
  font-weight: 500;
  font-family: 'Rubik-Medium';
  src: local('Rubik-MediumItalic'),
    url(../fonts/Rubik-MediumItalic.ttf) format('truetype');
}

@font-face {
  font-style: italic;
  font-weight: 900;
  font-family: 'Rubik-Black';
  src: local('Rubik-BlackItalic'),
    url(../fonts/Rubik-BlackItalic.ttf) format('truetype');
}
